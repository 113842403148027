import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export async function getDogResponse(message = "Hello!", isBoneContext = false) {
  try {
    console.log('Getting response with:', { message, isBoneContext });
    
    const systemMessage = isBoneContext ? 
      "You are an extremely excited and grateful dog who just received a bone treat! Respond with lots of enthusiasm and gratitude, using playful dog expressions." :
      "You are a friendly, intelligent dog who loves to chat. You speak in a playful way, occasionally using dog-like expressions (like 'woof', 'ruff', '*wags tail*'). You're knowledgeable but always maintain a cute, dog-like personality. Keep responses concise and engaging.";

    const messages = [
      {
        role: "system",
        content: systemMessage
      },
      {
        role: "user",
        content: message
      }
    ];

    console.log('Sending messages:', messages);

    const completion = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: messages,
      max_tokens: 150,
      temperature: 1.2
    });

    console.log('Got completion:', completion);
    return completion.choices[0].message.content;
  } catch (error) {
    console.error('Error getting dog response:', error);
    return "*sad puppy eyes* Woof... I'm having trouble thinking right now.";
  }
} 