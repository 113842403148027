import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { getDogResponse } from '../services/openai';

// Add this array at the top of your file, after imports
const LOCAL_DOG_IMAGES = [
  '/DogGifs/dog1.webp',
  '/DogGifs/dog2.webp',
  '/DogGifs/dog3.webp',
  '/DogGifs/dog4.webp',
  '/DogGifs/dog5.webp',
  '/DogGifs/dog6.webp',
  '/DogGifs/dog7.webp',
  '/DogGifs/dog8.webp',
  '/DogGifs/dog9.webp',
  '/DogGifs/dog10.webp',
  '/DogGifs/dog11.webp',
  '/DogGifs/dog12.webp',
  '/DogGifs/dog13.webp',
  '/DogGifs/dog14.webp',
  '/DogGifs/dog15.webp',
  '/DogGifs/dog16.webp',
  '/DogGifs/dog17.webp',
  '/DogGifs/dog18.webp',
  '/DogGifs/dog19.webp',
  '/DogGifs/dog20.webp',
  '/DogGifs/dog21.webp',
  '/DogGifs/dog22.webp',
  '/DogGifs/dog23.webp',
  '/DogGifs/dog24.webp',
  '/DogGifs/dog25.webp',
  '/DogGifs/dog26.webp',
  '/DogGifs/dog27.webp',
  '/DogGifs/dog28.webp',
  '/DogGifs/dog29.webp',
  '/DogGifs/dog30.webp',
  '/DogGifs/dog31.webp'
];

const ChatContainer = styled.div`
  background: white;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 40px);
  box-shadow: 0 4px 20px rgba(162, 136, 241, 0.15);
`;

const MessagesArea = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  padding-right: 12px;
  padding-bottom: 12px;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
`;

const Message = styled.div`
  padding: 16px;
  background: ${props => props.sender === 'user' ? '#FFE2E6' : '#E8F4FF'};
  border-radius: 12px;
  max-width: 80%;
  align-self: ${props => props.sender === 'user' ? 'flex-end' : 'flex-start'};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  img {
    vertical-align: middle;
    margin-right: 8px;
  }
`;

const InputArea = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 12px;
  padding-top: 12px;
  border-top: 1px solid #f0f0f0;
`;

const Input = styled.input`
  padding: 12px 16px;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 15px;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #A288F1;
    box-shadow: 0 0 0 3px rgba(162, 136, 241, 0.1);
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  background: ${props => props.primary ? '#A288F1' : '#5ee0ad'};
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    transform: translateY(-2px);
    background: ${props => props.primary ? '#8E6EF0' : '#FF9AAD'};
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
  }
`;

const TypingIndicator = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-style: italic;
  color: #666;
`;

// Rainbow animation
const rainbowBackground = keyframes`
  0% { background-color: rgba(255, 0, 0, 0.4); }
  17% { background-color: rgba(255, 165, 0, 0.4); }
  33% { background-color: rgba(255, 255, 0, 0.4); }
  50% { background-color: rgba(0, 255, 0, 0.4); }
  67% { background-color: rgba(0, 0, 255, 0.4); }
  83% { background-color: rgba(75, 0, 130, 0.4); }
  100% { background-color: rgba(238, 130, 238, 0.4); }
`;

const CelebrationContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1000;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  animation: ${rainbowBackground} 2s linear infinite;
`;

const CelebrationOverlay = ({ show, celebrationGif }) => {
  const [burstTriggers, setBurstTriggers] = useState([]);

  useEffect(() => {
    if (show) {
      // Initial burst
      setBurstTriggers([Date.now()]);
      
      // Second burst after 600ms
      setTimeout(() => {
        setBurstTriggers(prev => [...prev, Date.now() + 1]);
      }, 600);
      
      // Third burst after 1200ms
      setTimeout(() => {
        setBurstTriggers(prev => [...prev, Date.now() + 2]);
      }, 1200);

      // Fourth burst after 1800ms
      setTimeout(() => {
        setBurstTriggers(prev => [...prev, Date.now() + 3]);
      }, 1800);

      // Fifth burst after 2400ms
      setTimeout(() => {
        setBurstTriggers(prev => [...prev, Date.now() + 4]);
      }, 2400);

      // Sixth burst after 3000ms
      setTimeout(() => {
        setBurstTriggers(prev => [...prev, Date.now() + 5]);
      }, 3000);

      // Seventh burst after 3600ms
      setTimeout(() => {
        setBurstTriggers(prev => [...prev, Date.now() + 6]);
      }, 3600);

      return () => setBurstTriggers([]);
    }
  }, [show]);

  if (!show) return null;

  return (
    <CelebrationContainer>
      {burstTriggers.map(timestamp => (
        <BoneBurst key={timestamp} show={true} />
      ))}
      <FloatingBone src="/bone2.png" alt="Floating bone" />
      <GifContainer>
        {celebrationGif ? (
          <img 
            src={celebrationGif} 
            alt="Excited dog"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        ) : (
          <div>🐕 Waiting for treat...</div>
        )}
      </GifContainer>
    </CelebrationContainer>
  );
};

const GifContainer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  min-width: 300px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    max-width: 300px;
    max-height: 300px;
    border-radius: 10px;
  }
`;

// Add these new keyframe animations
const floatAndRotate = keyframes`
  0% {
    transform: rotate(-10deg) scale(0.9);
  }
  25% {
    transform: rotate(10deg) scale(1.0);
  }
  50% {
    transform: rotate(-10deg) scale(1.1);
  }
  75% {
    transform: rotate(10deg) scale(1.0);
  }
  100% {
    transform: rotate(-10deg) scale(0.9);
  }
`;

const FloatingBone = styled.img`
  position: absolute;
  top: 10%;
  width: 180px;
  height: auto;
  animation: ${floatAndRotate} 1s ease-in-out infinite;
  filter: drop-shadow(0 0 10px rgba(255,255,255,0.5));
`;

// Define keyframes first
const boneMotion = keyframes`
  from {
    offset-distance: 0%;
    opacity: 1;
  }
  to {
    offset-distance: 100%;
    opacity: 0;
  }
`;

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
`;

const BurstBone = styled.img`
  position: absolute;
  width: 60px;
  height: auto;
  offset-path: path('${props => props.$path}');
  
  /* Force initial state */
  offset-distance: 0%;
  opacity: 1;
  
  /* Combine animations with variable duration */
  animation: 
    ${boneMotion} ${props => props.$duration}s cubic-bezier(0.2, 0, 0.3, 1) forwards,
    ${spinAnimation} ${props => props.$duration}s linear infinite;
  
  animation-fill-mode: forwards;
  animation-play-state: running;
  
  pointer-events: none;
  z-index: 1000;
  will-change: offset-distance, transform, opacity;
`;

const BoneBurst = ({ show }) => {
  const [bones, setBones] = useState([]);

  useEffect(() => {
    if (show) {
      const newBones = Array.from({ length: 40 }, (_, i) => {
        const side = Math.random() < 0.5 ? 'left' : 'right';
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        
        // Start positions
        const startX = side === 'left' ? -30 : viewportWidth + 30;
        const startY = viewportHeight * (0.3 + Math.random() * 0.4);
        
        // End positions
        const endX = side === 'left'
          ? viewportWidth * (0.1 + Math.random() * 0.5)
          : viewportWidth * (0.4 + Math.random() * 0.2);
        
        const endY = viewportHeight + 800 + (Math.random() * 400);
        
        // Control point for arc
        const controlX = (startX + endX) / 2 + (Math.random() - 0.5) * 500;
        const controlY = Math.min(startY, endY) - (200 + Math.random() * 600);

        return {
          id: i,
          path: `M ${startX} ${startY} Q ${controlX} ${controlY} ${endX} ${endY}`,
          duration: 1.5 + Math.random() * 0.8 // Duration between 1.5 and 2.3 seconds
        };
      });
      
      setBones(newBones);
    } else {
      setBones([]);
    }
  }, [show]);

  return (
    <div style={{ position: 'fixed', inset: 0, pointerEvents: 'none', overflow: 'visible' }}>
      {bones.map(bone => (
        <BurstBone
          key={bone.id}
          src="/bone2.png"
          alt="Bursting bone"
          $path={bone.path}
          $duration={bone.duration}
        />
      ))}
    </div>
  );
};

const ChatInterface = ({ onBoneGiven }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showCelebration, setShowCelebration] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isGifLoading, setIsGifLoading] = useState(false);
  const [celebrationGif, setCelebrationGif] = useState('');
  const [message, setMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const audioRef = useRef(null);  // bell
  const barkRef = useRef(null);   // bark
  const barkIntervalRef = useRef(null);
  const processingTimeoutRef = useRef(null);

  const loadRandomImage = () => {
    setIsGifLoading(true);
    try {
      const randomIndex = Math.floor(Math.random() * LOCAL_DOG_IMAGES.length);
      const selectedImage = LOCAL_DOG_IMAGES[randomIndex];
      console.log('Setting celebration image:', selectedImage);
      setCelebrationGif(selectedImage);
    } catch (error) {
      console.log('Error loading celebration image:', error);
      setCelebrationGif(LOCAL_DOG_IMAGES[0]);
    } finally {
      setIsGifLoading(false);
    }
  };

  const handleBoneGiven = async () => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);
      setShowCelebration(true);
      
      // Add bone message with bone image
      setMessages(prev => [...prev, { 
        text: `<img src="/bone.png" alt="bone" style="width: 100px; height: auto;"/> *gives bone*`, 
        sender: 'user',
        timestamp: new Date().toISOString()
      }]);
      
      // Load a random celebration image
      loadRandomImage();
      
      // Play bell sound
      if (audioRef.current) {
        try {
          console.log('Starting bell sound...');
          audioRef.current.currentTime = 0;
          audioRef.current.loop = true;
          await audioRef.current.play();
        } catch (error) {
          console.log('Error in bell sound setup:', error);
        }
      }

      // Start random barks
      playRandomBarks();

      // Get dog response
      console.log('Getting dog response...');
      const response = await getDogResponse("Here's a bone for you!", true);
      console.log('Got response:', response);
      
      if (response) {
        setMessages(prev => [...prev, { 
          text: response, 
          sender: 'dog',
          timestamp: new Date().toISOString()
        }]);
      }

      // Notify parent component about bone given (for happy face)
      if (onBoneGiven) {
        onBoneGiven();
      }

      // Shorter celebration duration (5 seconds)
      setTimeout(() => {
        setShowCelebration(false);
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
        if (barkIntervalRef.current) {
          clearTimeout(barkIntervalRef.current);
        }
        setCelebrationGif('');
        setIsProcessing(false);
      }, 3000);

    } catch (error) {
      console.error('Error in handleBoneGiven:', error);
      setIsProcessing(false);
      setShowCelebration(false);
    }
  };

  // Clean up on unmount
  useEffect(() => {
    if (showCelebration) {
      // Store refs in variables
      const currentProcessingTimeout = processingTimeoutRef.current;
      const currentAudio = audioRef.current;

      return () => {
        if (currentProcessingTimeout) {
          clearTimeout(currentProcessingTimeout);
        }
        if (currentAudio) {
          currentAudio.pause();
          currentAudio.currentTime = 0;
        }
      };
    }
  }, [showCelebration]);

  // Add the playRandomBarks function
  const playRandomBarks = () => {
    console.log('Starting random barks');
    
    if (barkIntervalRef.current) {
      clearTimeout(barkIntervalRef.current);
    }

    const playBark = () => {
      console.log('Playing bark');
      if (barkRef.current) {
        barkRef.current.currentTime = 0;
        const playPromise = barkRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            console.log('Error playing bark:', error);
          });
        }
      }
    };

    const bark = () => {
      playBark();
      const nextInterval = Math.random() * (700 - 400) + 400;
      console.log('Next bark in:', nextInterval, 'ms');
      barkIntervalRef.current = setTimeout(bark, nextInterval);
    };

    bark();
  };

  // Add handleSend function
  const handleSend = async () => {
    if (!message.trim()) return;

    setMessages(prev => [...prev, { 
      text: message, 
      sender: 'user',
      timestamp: new Date().toISOString()
    }]);
    setMessage('');
    setIsTyping(true);

    try {
      const response = await getDogResponse(message);
      setMessages(prev => [...prev, { 
        text: response, 
        sender: 'dog',
        timestamp: new Date().toISOString()
      }]);
    } catch (error) {
      console.error('Error getting response:', error);
    } finally {
      setIsTyping(false);
    }
  };

  return (
    <>
      <audio ref={audioRef} src="/bell.mp3" preload="auto" loop />
      <audio ref={barkRef} src="/bark.mp3" preload="auto" />
      
      <CelebrationOverlay 
        show={showCelebration} 
        celebrationGif={celebrationGif}
      >
        <GifContainer>
          {isGifLoading ? (
            <div> Loading...</div>
          ) : celebrationGif ? (
            <img 
              src={celebrationGif} 
              alt="Happy dog"
              style={{ 
                maxWidth: '100%', 
                height: 'auto',
                display: 'block' // Ensure image displays
              }}
              onError={(e) => {
                console.error('Error loading image:', e);
                // Try loading a different image on error
                const randomIndex = Math.floor(Math.random() * LOCAL_DOG_IMAGES.length);
                setCelebrationGif(LOCAL_DOG_IMAGES[randomIndex]);
              }}
            />
          ) : (
            <div>🐕 Loading celebration...</div>
          )}
        </GifContainer>
      </CelebrationOverlay>
      
      <ChatContainer>
        <MessagesArea>
          {isTyping && (
            <Message sender="dog">
              <TypingIndicator>*thinking*</TypingIndicator>
            </Message>
          )}
          {[...messages].reverse().map((msg, idx) => (
            <Message key={idx} sender={msg.sender}>
              <div dangerouslySetInnerHTML={{ __html: msg.text }} />
            </Message>
          ))}
        </MessagesArea>
        <InputArea>
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message..."
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          />
          <Button primary onClick={handleSend}>
            Talk to me! Woof! ❤️
          </Button>
          <Button onClick={handleBoneGiven}>
            Give bone 🦴
          </Button>
        </InputArea>
      </ChatContainer>
    </>
  );
};

export default ChatInterface; 