import React, { useState } from 'react';
import styled from '@emotion/styled';
import CustomizationPanel from './components/CustomizationPanel';
import ChatInterface from './components/ChatInterface';

const AppContainer = styled.div`
  height: 100%;
  background: #faf4ff;
  padding: 20px;
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 24px;
  overflow: hidden;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    padding: 16px;
  }
`;

function App() {
  const [customizations, setCustomizations] = useState({
    dogType: 'pug'
  });
  const [isHappy, setIsHappy] = useState(false);

  const handleBoneGiven = () => {
    setIsHappy(true);
    setTimeout(() => {
      setIsHappy(false);
    }, 5500); // 5.5 seconds (slightly longer than celebration)
  };

  return (
    <AppContainer>
      <CustomizationPanel 
        customizations={customizations} 
        setCustomizations={setCustomizations}
        isHappy={isHappy}
      />
      <ChatInterface 
        onBoneGiven={handleBoneGiven}
      />
    </AppContainer>
  );
}

export default App;