import React from 'react';
import styled from '@emotion/styled';

const Panel = styled.div`
  background: white;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-shadow: 0 4px 20px rgba(162, 136, 241, 0.15);
  position: relative;
`;

const Header = styled.h1`
  margin: 0 0 0 0;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  text-align: center;
`;

const DogVisual = styled.div`
  aspect-ratio: 1/1;
  background: #A4CAFE;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 14px;
  color: #666;
  padding: 8px;
`;

const CustomizationPanel = ({ customizations, setCustomizations, isHappy }) => {
  return (
    <Panel>
      <Header>Good Doggy Bot</Header>
      <DogVisual>
        <img 
          src={isHappy ? "/dog1-happy.png" : "/dog1-default.png"}
          alt="Dog character"
        />
      </DogVisual>
      <Footer>
        made with ❤️ by AE Studio
      </Footer>
    </Panel>
  );
};

export default CustomizationPanel; 